<template>
  <div class="p-4">
    <div v-if="schedules.length">
      <h3>Agendamentos</h3>
      <div class="p-4 flex flex-col gap-2 bg-white rounded-md mb-4">
        <div
          class="flex flex-col gap-4"
          v-for="schedule in schedules"
          :key="schedule"
        >
          <div
            class="flex bg-gray-200 hover:bg-slate-300 gap-8 rounded-md py-2 px-4 justify-between items-center"
          >
            <div class="flex text-[14px] flex-col lg:flex-row justify-between w-full gap-2">
              <span
                >{{ schedule.schedule_qty }} posts a cada
                {{ schedule.schedule_frequency }}
                {{ schedule.schedule_frequency_type }} do site
                {{ domain(schedule.schedule_url) }}
                <span v-if="schedule.schedule_search_config?.category?.name">
                  da categoria
                  {{ schedule.schedule_search_config?.category?.name || "--" }}
                </span>
                <span v-if="schedule.schedule_search_config?.search">
                  com a palavra chave
                  {{ schedule.schedule_search_config?.search || "--" }}
                </span>
                <span v-if="schedule.schedule_search_config?.date">
                  e data {{ schedule.schedule_search_config?.date || "--" }}
                </span>
                nos sites
                {{ schedule.domains.map((domain) => domain.url).join(", ") }}
                <span v-if="schedule.schedule_start_date">
                  a partir de {{ schedule.schedule_start_date }}
                </span>
                <span v-if="schedule.schedule_end_date">
                  até {{ schedule.schedule_end_date }}
                </span>
              </span>
              <span
                ><strong>Próxima execução:</strong>
                {{ schedule.next_run }}</span
              >
            </div>
            <div>
              <button type="button" @click="destroySchedule(schedule)">
                remover
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div
      class="w-full flex flex-col items-center p-4 lg:p-8 bg-white z-50 rounded-md"
    >
      <h3 class="text-[1em] font-bold text-left w-full">Como funciona?</h3>
      <p class="text-left text-[13px] w-full mb-4">
        O agendamento será feito com as configurações utilizadas nessa pesquisa.
        Os posts mostrados como resultado são apenas para referência. Cada vez
        que o agendamento for executado ele usará essas configurações para
        buscar os posts.
      </p>
      <div class="w-full flex flex-col lg:flex-row gap-4 items-center">
        <select
          v-model="fontePosts"
          class="h-[40px] bg-gray-200 text-black px-2 rounded-md w-full"
        >
          <option value="">Selecione o site</option>
          <template v-for="(source, key) in sites_history" :key="source">
            <option class="py-2" :value="source.url">
              {{ source.url }}
            </option>
          </template>
        </select>
        <div class="flex w-full justify-between lg:w-auto gap-4 items-center">
          <button
            @click="buscarPosts"
            class="whitespace-nowrap w-full lg:w-auto items-center text-white bg-green-700 text-center rounded-md px-4 uppercase text-[12px] font-bold h-[40px]"
          >
            buscar
          </button>
          <button
            @click="addSource"
            class="flex items-center gap-2 whitespace-nowrap"
          >
            <font-awesome-icon icon="fa-solid fa-plus-circle" /><span
              class="text-[11px]"
              >Adicionar fonte</span
            >
          </button>
          <button
            @click="removeSource"
            class="flex items-center gap-2 whitespace-nowrap"
          >
            <font-awesome-icon icon="fa-solid fa-minus-circle" /><span
              class="text-[11px]"
              >Remover fonte</span
            >
          </button>
        </div>
      </div>
      <div
        v-if="currentSource?.is_wordpress"
        class="w-full flex-wrap lg:flex-nowrap flex gap-4 items-center justify-between mt-4"
      >
        <select
          type="text"
          @change="getFonteCats"
          v-model="remote_cat"
          class="h-[40px] bg-gray-200 text-black px-2 rounded-md w-full"
          placeholder="Palavra chave"
        >
          <option value="">{{ fonteCatText }}</option>
          <template v-for="cat in remote_cats" :key="cat.id">
            <option :value="cat.id" v-html="cat.name"></option>
          </template>
        </select>
        <input
          type="text"
          v-model="remote_search"
          class="h-[40px] bg-gray-200 text-black px-2 rounded-md w-full"
          placeholder="Palavra chave"
        />
        <input
          type="date"
          v-model="remote_date_search"
          class="h-[40px] bg-gray-200 text-black px-2 rounded-md w-full"
          placeholder="Data"
        />
      </div>
      <div
        class="w-full flex overflow-y-auto lg:h-auto flex-col justify-start items-start gap-4"
        v-if="posts.length"
      >
        <span class="text-[11px] text-blue-600 mt-4">exemplos de posts</span>
        <div
          class="flex w-full flex-col lg:flex-row justify-between items-center"
          v-for="post in posts.slice(0, 10)"
          :key="post"
        >
          <template v-if="post && post?.title">
            <span
              @click="previewPost(post)"
              class="text-[14px] text-normal text-ellipsis overflow-hidden"
              >{{ post.date.replace("T", " ") }} -
              {{ decode(post.title.rendered) }}</span
            >
            <div class="flex w-full lg:w-auto gap-4 mt-2 lg:mt-0">
              <a
                :href="post.link"
                target="_blank"
                class="px-4 py-1 cursor-pointer rounded-md text-white w-full lg:w-auto bg-blue-500 text-[11px] text-center"
                >ver</a
              >
            </div>
          </template>
        </div>
      </div>
    </div>
    <div v-if="schedule_url" class="flex justify-between w-full">
      <button
        type="button"
        class="bg-yellow-400 py-1 w-full h-[50px] px-4 mt-4 text-black uppercase text-[14px] font-bold rounded-md"
        @click="postar"
      >
        Agendar
      </button>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { version } from "../../package";
import mitt from "mitt";
import { decode } from "html-entities";
import SidebarComponent from "../components/SidebarComponent.vue";

const emitter = mitt();

export default {
  name: "ScheduleView",
  components: {
    SidebarComponent,
  },
  data() {
    return {
      steps: [
        {
          attachTo: { element: "#sync" },
          content: { title: "Sincronize seus dados entre os dispositivos" },
        },
      ],
      schedule_url: "",
      search: 0,
      windowWidth: window.innerWidth,
      blur: false,
      view: 1,
      saving: false,
      update_post: false,
      draft: 1,
      version,
      sites_history: [],
      tab: 0,
      currentPost: false,
      queue: {},
      image: false,
      remote_search: "",
      remote_cat: "",
      remote_date: "",
      remote_date_search: "",
      remote_cats: [],
      focusText: false,
      fonte: "",
      fonte_selecionada: "",
      addsite: false,
      url_new_site: null,
      url: null,
      caption: null,
      sites: {},
      posts: [],
      usarOriginal: false,
      fontePosts: "",
      autosaving: false,
      form: {
        cost: 0,
        post_id: 0,
        title: "",
        content: "",
        fonte: "",
        caption: "",
      },
      currentSite: false,
      activeSite: false,
      date: new Date().toLocaleDateString(),
      tracking: {},
      fonteCatText: "Categoria",
      schedules: [],
    };
  },
  watch: {
    fontePosts() {
      try {
        let u = new URL(this.fontePosts);
        let _cat_url = `https://${u.hostname}/wp-json/wp/v2/categories/?_fields=id,name&per_page=100`;
        console.log(_cat_url);
        let cat_url = encodeURIComponent(_cat_url);
        this.$axios.get(`${this.proxyUrl}/?url=${cat_url}`).then((res) => {
          if (res.data.message) {
            return;
          }
          this.remote_cats = res.data;
        });
      } catch (e) {
        this.remote_cats = [];
      }
    },
  },
  mounted() {
    if (!localStorage.user) {
      this.$router.push("/");
      return;
    }

    this.$axios.get("schedule").then((res) => (this.schedules = res.data));
    this.$axios.get("sources").then((res) => (this.sites_history = res.data));

    this.getQueue();

    window.addEventListener(
      "resize",
      () => (this.windowWidth = window.innerWidth)
    );
  },
  computed: {
    selecteds() {
      return this.queue.map((item) => item.url);
    },
    category_selected() {
      return this.remote_cats.filter((item) => item.id == this.remote_cat)[0];
    },
    currentSource() {
      return (
        this.sites_history.filter((item) => item.url == this.fontePosts)[0] ??
        {}
      );
    },
    isMobile() {
      return this.windowWidth < 800;
    },
    configs() {
      return this.$store.state.configs;
    },
    me() {
      return this.$store.state.me;
    },
    cost() {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(this.form.cost || 0);
    },
    gtag() {
      return window.gtag;
    },
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    proxyUrl() {
      return process.env.VUE_APP_PROXY_URL;
    },
    hasSelected() {
      console.log(this.$store.state.configs.sites);
      return Array.from(this.$store.state.configs.sites).filter(
        (site) => site.postTo
      ).length;
    },
  },
  methods: {
    domain(url) {
      try {
        return new URL(url).hostname;
      } catch (e) {
        return url;
      }
    },
    destroySchedule(schedule) {
      this.$axios.delete(`schedule/${schedule._id.$oid}`).then(() => {
        this.schedules = this.schedules.filter((item) => item != schedule);
      });
    },
    postar() {
      sessionStorage.step = 1;
      sessionStorage.schedule_search_config = JSON.stringify({
        category: this.category_selected,
        search: this.remote_search,
        date: this.remote_date_search,
      });

      this.$router.push({
        name: "postar",
        query: { agendar: 1, url: this.schedule_url },
      });
    },
    isInQueue(post) {
      return this.queue.find((item) => item.url == post.link);
    },
    getQueue() {
      this.$axios.get("queue").then((res) => {
        this.queue = res.data;
      });
    },
    previewPost(post) {
      this.currentPost = post;
      this.postThis(post, 0, 1).catch(console.log);
    },
    addSource() {
      let url = prompt("Insira a URL do site");
      try {
        const fccUrl = new URL(url);
        if (this.sites_history[url]) return;
        this.$axios.post("add-source", { url }).then((res) => {
          this.sites_history = res.data;
          this.fontePosts = url;
          // this.$toast.success("Fonte adicionada");
        });
      } catch (err) {
        this.$toast.error("URL inválida");
      }
    },
    removeSource() {
      this.$axios.post("remove-source", { url: this.fontePosts }).then(() => {
        this.$toast.success("Fonte removida");
        this.fontePosts = "";
      });
    },
    removeFromList(_post) {
      let index = this.queue.indexOf(_post);
      this.queue.splice(index, 1);
      this.$axios.delete(`queue/${_post._id.$oid}`);
    },
    addToList(post) {
      let _post;
      if ((_post = this.isInQueue(post))) {
        this.removeFromList(_post);
        return;
      }
      post.image = "";
      if (post._embedded && post._embedded["wp:featuredmedia"]) {
        post.image = post._embedded["wp:featuredmedia"][0].source_url;
      } else {
        if (
          post.better_featured_image &&
          post.better_featured_image.source_url
        ) {
          post.image = post.better_featured_image.source_url;
        }
      }
      const _POST = {
        post_id: post.id,
        title: post.title.rendered,
        content: post.content.rendered,
        image: post.image,
        url: post.link,
      };
      this.queue.unshift(_POST);
      this.$axios.post("queue", _POST);
    },
    validUrl(urlString) {
      var urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator
      return !!urlPattern.test(urlString);
    },
    buscarPosts() {
      if (!this.fontePosts) return;
      if (!this.fontePosts.match(/http/gi))
        this.fontePosts = `https://${this.fontePosts}`;
      if (this.currentSource?.is_wordpress) {
        this.$wordpressSearch(this);
      } else {
        this.$rssSearch(this);
      }
    },
    decode(str) {
      return decode(str);
    },
  },
};
</script>
