import { createApp } from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import axios from '@/plugins/axios'
import monitor from '@/plugins/monitor'
import './assets/tailwind.scss'
import 'animate.css'
import { library } from '@fortawesome/fontawesome-svg-core'
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'
import { faCamera, faExternalLink, faFileImport, faMagicWandSparkles, faPlug, faGear, faPlusCircle, faMinusCircle, faCog, faNetworkWired, faDiagramProject, faAlignLeft, faCloudArrowUp, faRotateRight, faTriangleExclamation, faClockRotateLeft, faGlobe, faSearch, faDownload, faBars, faEdit, faListCheck, faRobot, faCalendarDays } from '@fortawesome/free-solid-svg-icons'
import { faWordpressSimple, faSearchengin } from '@fortawesome/free-brands-svg-icons'
import toast from './plugins/toast'
import wordpress from './plugins/wordpress'
import rssSearch from './plugins/rssSearch'
import VueShepherdPlugin from 'vue-shepherd';
import 'shepherd.js/dist/css/shepherd.css';
import { CkeditorPlugin } from '@ckeditor/ckeditor5-vue';
import 'ckeditor5/ckeditor5.css';
import bus from './plugins/bus'

library.add(faPlug, faBars, faCalendarDays, faRobot, faListCheck, faEdit, faCamera, faGlobe, faDownload, faSearch, faMagicWandSparkles, faFileImport, faSearchengin, faClockRotateLeft, faRotateRight, faTriangleExclamation, faExternalLink, faWordpressSimple, faGear, faPlusCircle, faCloudArrowUp, faMinusCircle, faCog, faNetworkWired, faDiagramProject, faGear, faAlignLeft)

window.addEventListener('load', function(){

    createApp(App)
    .component('fa', FontAwesomeIcon)
    .component('font-awesome-icon', FontAwesomeIcon)
    .use(monitor)
    .use(toast)
    .use(bus)
    .use(wordpress)
    .use(rssSearch)
    .use(CkeditorPlugin)
    .use(VueShepherdPlugin)
    .use(axios)
    .use(store)
    .use(router)
    .mount('#app')
})
