<template>
  <div class="cols p-4">
    <div
      class="lg:w-4/6 w-full flex flex-col items-center p-4 lg:p-8 bg-white z-50 rounded-md"
    >
      <div class="w-full flex flex-col lg:flex-row gap-4 items-center">
        <select
          v-model="fontePosts"
          class="h-[40px] bg-gray-200 text-black px-2 rounded-md w-full"
        >
          <option value="">Selecione o site</option>
          <template v-for="(source, key) in sites_history" :key="source">
            <option class="py-2" :value="source.url">
              {{ source.url }}
            </option>
          </template>
        </select>
        <div class="flex w-full justify-between lg:w-auto gap-4 items-center">
          <button
            @click="buscarPosts"
            class="whitespace-nowrap w-full lg:w-auto items-center text-white bg-green-700 text-center rounded-md px-4 uppercase text-[12px] font-bold h-[40px]"
          >
            buscar
          </button>
          <button @click="addSource" class="flex items-center gap-2 whitespace-nowrap">
            <font-awesome-icon icon="fa-solid fa-plus-circle" /><span class="text-[11px]">Adicionar fonte</span>
          </button>
          <button @click="removeSource" class="flex items-center gap-2 whitespace-nowrap">
            <font-awesome-icon icon="fa-solid fa-minus-circle" /><span class="text-[11px]">Remover fonte</span>
          </button>
        </div>
      </div>
      <div
        v-if="currentSource?.is_wordpress"
        class="w-full flex-wrap lg:flex-nowrap flex gap-4 items-center justify-between mt-4"
      >
        <select
          type="text"
          @change="getFonteCats"
          v-model="remote_cat"
          class="h-[40px] bg-gray-200 text-black px-2 rounded-md w-full"
          placeholder="Palavra chave"
        >
          <option value="">{{ fonteCatText }}</option>
          <template v-for="cat in remote_cats" :key="cat.id">
            <option :value="cat.id" v-html="cat.name"></option>
          </template>
        </select>
        <input
          type="text"
          v-model="remote_search"
          class="h-[40px] bg-gray-200 text-black px-2 rounded-md w-full"
          placeholder="Palavra chave"
        />
        <input
          type="date"
          v-model="remote_date_search"
          class="h-[40px] bg-gray-200 text-black px-2 rounded-md w-full"
          placeholder="Data"
        />
      </div>
      <div
        class="w-full flex overflow-y-auto lg:h-auto flex-col justify-start items-start gap-4"
        v-if="posts.length"
      >
        <span class="text-[11px] text-blue-600 mt-4"
          >mostrando últimos 20 posts</span
        >
        <div
          class="flex w-full flex-col lg:flex-row justify-between items-center"
          v-for="post in posts"
          :key="post"
        >
          <template v-if="post && post?.title">
            <span
              @click="previewPost(post)"
              class="text-[14px] text-normal text-ellipsis overflow-hidden"
              >{{ post.date.replace("T", " ") }} -
              {{ decode(post.title.rendered) }}</span
            >
            <div class="flex w-full lg:w-auto gap-4 mt-2 lg:mt-0">
              <button
                @click="addToList(post)"
                :class="{
                  'text-white': isInQueue(post),
                  'text-black': !isInQueue(post),
                  'bg-green-500': isInQueue(post),
                  'bg-gray-300': !isInQueue(post),
                }"
                class="px-4 py-1 rounded-md text-[11px] text-center justify-center w-full lg:w-auto flex items-center gap-2"
              >
                {{ isInQueue(post) ? "selecionado" : "selecionar" }}
                <span
                  v-if="post.id == currentPost?.id"
                  class="w-[5px] h-[5px] rounded-[10px] bg-white block"
                ></span>
              </button>
              <a
                :href="post.link"
                target="_blank"
                class="px-4 py-1 cursor-pointer rounded-md text-white w-full lg:w-auto bg-blue-500 text-[11px] text-center"
                >ver</a
              >
            </div>
          </template>
        </div>
      </div>
    </div>
    <div
      class="flex items-start justify-start lg:w-2/6 w-full bg-white p-4 rounded-md flex-col"
    >
      <h2 class="text-[14px] text-black text-lg">{{ queue.length}} posts selecionados</h2>
      <div class="w-full">
        <template v-for="(post, index) in queue" :key="index">
          <div class="flex gap-4 text-[12px] items-center justify-between w-full py-2 my-2 border-b-2">
            <span v-html="post.title"></span>
            <button @click="removeFromList(post)" class="btn danger" id="sync">
              X
            </button>
          </div>
        </template>
      </div>
      <div
        v-if="Object.keys(queue).length > 0"
        class="flex justify-between w-full"
      >
        <button
          type="button"
          class="bg-yellow-400 py-1 w-full h-[50px] px-4 mt-4 text-black uppercase text-[14px] font-bold rounded-md"
          @click="postar"
        >
          Postar
        </button>
      </div>
    </div>
  </div>
</template>

<script>
// @ is an alias to /src
import { version } from "../../package";
import mitt from "mitt";
import { decode } from "html-entities";
import SidebarComponent from "../components/SidebarComponent.vue";

const emitter = mitt();

export default {
  name: "SearchView",
  components: {
    SidebarComponent,
  },
  data() {
    return {
      steps: [
        {
          attachTo: { element: "#sync" },
          content: { title: "Sincronize seus dados entre os dispositivos" },
        },
      ],
      search: 0,
      windowWidth: window.innerWidth,
      blur: false,
      view: 1,
      saving: false,
      update_post: false,
      draft: 1,
      version,
      sites_history: [],
      tab: 0,
      currentPost: false,
      queue: {},
      image: false,
      remote_search: "",
      remote_cat: "",
      remote_date: "",
      remote_date_search: "",
      remote_cats: [],
      focusText: false,
      fonte: "",
      fonte_selecionada: "",
      addsite: false,
      url_new_site: null,
      url: null,
      caption: null,
      sites: {},
      posts: [],
      usarOriginal: false,
      fontePosts: "",
      autosaving: false,
      form: {
        cost: 0,
        post_id: 0,
        title: "",
        content: "",
        fonte: "",
        caption: "",
      },
      currentSite: false,
      activeSite: false,
      date: new Date().toLocaleDateString(),
      tracking: {},
      fonteCatText: "Categoria",
    };
  },
  watch: {
    fontePosts() {
      try {
        let u = new URL(this.fontePosts);
        let _cat_url = `https://${u.hostname}/wp-json/wp/v2/categories/?_fields=id,name&per_page=100`;
        let cat_url = encodeURIComponent(_cat_url);
        this.$axios.get(`${this.proxyUrl}/?url=${cat_url}`).then((res) => {
          if (res.data.message) {
            return;
          }
          this.remote_cats = res.data;
        });
      } catch (e) {
        this.remote_cats = [];
      }
    },
  },
  mounted() {
    if (!localStorage.user) {
      this.$router.push("/");
      return;
    }

    this.$axios.get("sources").then((res) => (this.sites_history = res.data));

    this.getQueue();

    window.addEventListener(
      "resize",
      () => (this.windowWidth = window.innerWidth)
    );
  },
  computed: {
    selecteds() {
      return this.queue.map((item) => item.url);
    },
    currentSource() {
      return (
        this.sites_history.filter((item) => item.url == this.fontePosts)[0] ??
        {}
      );
    },
    isMobile() {
      return this.windowWidth < 800;
    },
    configs() {
      return this.$store.state.configs;
    },
    me() {
      return this.$store.state.me;
    },
    cost() {
      return new Intl.NumberFormat("pt-BR", {
        style: "currency",
        currency: "BRL",
      }).format(this.form.cost || 0);
    },
    gtag() {
      return window.gtag;
    },
    apiUrl() {
      return process.env.VUE_APP_API_URL;
    },
    proxyUrl() {
      return process.env.VUE_APP_PROXY_URL;
    },
    hasSelected() {
      console.log(this.$store.state.configs.sites);
      return Array.from(this.$store.state.configs.sites).filter(
        (site) => site.postTo
      ).length;
    },
  },
  methods: {
    postar() {
      sessionStorage.step = 1
      this.$router.push({name: 'postar'})
    },
    isInQueue(post) {
      return this.queue.find((item) => item.url == post.link);
    },
    getQueue() {
      this.$axios.get("queue").then((res) => {
        this.queue = res.data;
      });
    },
    previewPost(post) {
      this.currentPost = post;
      this.postThis(post, 0, 1).catch(console.log);
    },
    addSource() {
      let url = prompt("Insira a URL do site");
      try {
        const fccUrl = new URL(url);
        if (this.sites_history[url]) return;
        this.$axios.post("add-source", { url }).then((res) => {
          this.sites_history = res.data;
          this.fontePosts = url;
          // this.$toast.success("Fonte adicionada");
        });
      } catch (err) {
        this.$toast.error("URL inválida");
      }
    },
    removeSource() {
      this.$axios.post("remove-source", { url: this.fontePosts }).then(() => {
        this.$toast.success("Fonte removida");
        this.fontePosts = "";
      });
    },
    removeFromList(_post) {
      let index = this.queue.indexOf(_post);
      this.queue.splice(index, 1);
      this.$axios.delete(`queue/${_post._id.$oid}`);
    },
    addToList(post) {
      let _post;
      if ((_post = this.isInQueue(post))) {
        this.removeFromList(_post);
        return;
      }
      post.image = "";
      if (post._embedded && post._embedded["wp:featuredmedia"]) {
        post.image = post._embedded["wp:featuredmedia"][0].source_url;
      } else {
        if (
          post.better_featured_image &&
          post.better_featured_image.source_url
        ) {
          post.image = post.better_featured_image.source_url;
        }
      }
      const _POST = {
        post_id: post.id,
        title: post.title.rendered,
        content: post.content.rendered,
        image: post.image,
        url: post.link,
      };
      this.queue.unshift(_POST);
      this.$axios.post("queue", _POST);
    },
    validUrl(urlString) {
      var urlPattern = new RegExp(
        "^(https?:\\/\\/)?" + // validate protocol
          "((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|" + // validate domain name
          "((\\d{1,3}\\.){3}\\d{1,3}))" + // validate OR ip (v4) address
          "(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*" + // validate port and path
          "(\\?[;&a-z\\d%_.~+=-]*)?" + // validate query string
          "(\\#[-a-z\\d_]*)?$",
        "i"
      ); // validate fragment locator
      return !!urlPattern.test(urlString);
    },
    buscarPosts() {
      if (!this.fontePosts) return;
      if (!this.fontePosts.match(/http/gi))
        this.fontePosts = `https://${this.fontePosts}`;
      if (this.currentSource?.is_wordpress) {
        this.$wordpressSearch(this);
      } else {
        this.$rssSearch(this);
      }
    },
    decode(str) {
      return decode(str);
    },
  },
};
</script>
