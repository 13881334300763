<template>
  <div class="flex flex-col items-center justify-center bg-slate-200">
    <div
      class="flex flex-col-reverse lg:flex-row lg:min-h-[100vh] items-stretch w-full"
    >
      <div
        class="p-8 lg:p-0 min-h-[50vh] lg:min-h-screen w-full lg:w-1/2 bg-white flex flex-col items-center justify-center"
      >
        <div class="flex flex-col text-center">
          <div class="hidden lg:block mb-8">
            <logo-component class="scale-[200%] mb-8"></logo-component>
            <h1 class="text-[1.2em] font-normal">
              Crie e gerencie posts em sites <a class="text-red-500" href="https://dischargepermit.com/fm44cw1zk1?key=57bd0ca3ebc1887a61c279066b5283da">WordPress</a>
            </h1>
          </div>
          <svg
            class="m-auto"
            width="330"
            height="50"
            viewBox="0 0 330 50"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              d="M49.32 43.2356L39.583 33.4985C39.1435 33.0591 38.5477 32.8149 37.9227 32.8149H36.3308C39.0263 29.3674 40.628 25.0311 40.628 20.314C40.628 9.09246 31.5355 0 20.314 0C9.09246 0 0 9.09246 0 20.314C0 31.5355 9.09246 40.628 20.314 40.628C25.0311 40.628 29.3674 39.0263 32.8149 36.3308V37.9227C32.8149 38.5477 33.0591 39.1435 33.4985 39.583L43.2356 49.32C44.1536 50.2381 45.6381 50.2381 46.5464 49.32L49.3102 46.5561C50.2283 45.6381 50.2283 44.1536 49.32 43.2356ZM20.314 32.8149C13.4092 32.8149 7.81307 27.2286 7.81307 20.314C7.81307 13.4092 13.3994 7.81307 20.314 7.81307C27.2188 7.81307 32.8149 13.3994 32.8149 20.314C32.8149 27.2188 27.2286 32.8149 20.314 32.8149Z"
              fill="#838383"
            />
            <path
              d="M140 25V35C140 36.3828 141.117 37.5 142.5 37.5H145V22.5H142.5C141.117 22.5 140 23.6172 140 25ZM176.25 12.5H167.5V7.5C167.5 6.11719 166.383 5 165 5C163.617 5 162.5 6.11719 162.5 7.5V12.5H153.75C150.297 12.5 147.5 15.2969 147.5 18.75V40C147.5 42.7578 149.742 45 152.5 45H177.5C180.258 45 182.5 42.7578 182.5 40V18.75C182.5 15.2969 179.703 12.5 176.25 12.5ZM160 37.5H155V35H160V37.5ZM157.5 28.125C155.773 28.125 154.375 26.7266 154.375 25C154.375 23.2734 155.773 21.875 157.5 21.875C159.227 21.875 160.625 23.2734 160.625 25C160.625 26.7266 159.227 28.125 157.5 28.125ZM167.5 37.5H162.5V35H167.5V37.5ZM175 37.5H170V35H175V37.5ZM172.5 28.125C170.773 28.125 169.375 26.7266 169.375 25C169.375 23.2734 170.773 21.875 172.5 21.875C174.227 21.875 175.625 23.2734 175.625 25C175.625 26.7266 174.227 28.125 172.5 28.125ZM187.5 22.5H185V37.5H187.5C188.883 37.5 190 36.3828 190 35V25C190 23.6172 188.883 22.5 187.5 22.5Z"
              fill="#838383"
            />
            <path
              d="M121.707 25.7071C122.098 25.3166 122.098 24.6834 121.707 24.2929L115.343 17.9289C114.953 17.5384 114.319 17.5384 113.929 17.9289C113.538 18.3195 113.538 18.9526 113.929 19.3431L119.586 25L113.929 30.6569C113.538 31.0474 113.538 31.6805 113.929 32.0711C114.319 32.4616 114.953 32.4616 115.343 32.0711L121.707 25.7071ZM69 26H121V24H69V26Z"
              fill="#787878"
            />
            <path
              d="M261.707 25.7071C262.098 25.3166 262.098 24.6834 261.707 24.2929L255.343 17.9289C254.953 17.5384 254.319 17.5384 253.929 17.9289C253.538 18.3195 253.538 18.9526 253.929 19.3431L259.586 25L253.929 30.6569C253.538 31.0474 253.538 31.6805 253.929 32.0711C254.319 32.4616 254.953 32.4616 255.343 32.0711L261.707 25.7071ZM209 26H261V24H209V26Z"
              fill="#787878"
            />
            <path
              d="M305 0C291.22 0 280 11.2097 280 25C280 38.7802 291.22 50 305 50C318.78 50 330 38.7802 330 25C330 11.2097 318.78 0 305 0ZM282.52 25C282.52 21.744 283.216 18.6492 284.466 15.8569L295.192 45.2319C287.692 41.5827 282.52 33.8911 282.52 25ZM305 47.4798C302.792 47.4798 300.665 47.1573 298.649 46.5625L305.393 26.9657L312.298 45.8972C312.349 46.0081 312.399 46.1089 312.46 46.2097C310.131 47.0262 307.621 47.4798 305 47.4798ZM308.095 14.4657C309.446 14.3952 310.665 14.254 310.665 14.254C311.875 14.1129 311.734 12.3286 310.524 12.3992C310.524 12.3992 306.885 12.6815 304.536 12.6815C302.329 12.6815 298.619 12.3992 298.619 12.3992C297.409 12.3286 297.268 14.1835 298.478 14.254C298.478 14.254 299.627 14.3952 300.837 14.4657L304.335 24.0625L299.415 38.8105L291.23 14.4657C292.581 14.3952 293.8 14.254 293.8 14.254C295.01 14.1129 294.869 12.3286 293.659 12.3992C293.659 12.3992 290.02 12.6815 287.671 12.6815C287.248 12.6815 286.754 12.6714 286.22 12.6512C290.242 6.55242 297.147 2.52016 305 2.52016C310.847 2.52016 316.179 4.75806 320.181 8.41734C320.081 8.40726 319.99 8.39718 319.889 8.39718C317.681 8.39718 316.119 10.3226 316.119 12.3891C316.119 14.244 317.187 15.8065 318.327 17.6613C319.183 19.1532 320.181 21.0786 320.181 23.8609C320.181 25.7863 319.446 28.0141 318.468 31.129L316.23 38.619L308.095 14.4657ZM316.3 44.4254L323.165 24.5766C324.446 21.371 324.879 18.8105 324.879 16.5222C324.879 15.6956 324.829 14.9294 324.728 14.2137C326.482 17.4194 327.48 21.0887 327.48 25C327.48 33.2964 322.984 40.5343 316.3 44.4254Z"
              fill="#838383"
            />
          </svg>
          <div
            class="m-4 flex flex-col text-[1.3em] lg:flex-row gap-4 lg:gap-8 items-center justify-center"
          >
            <span>1. Instale o plugin</span>
            <span>2. Conecte o site</span>
            <span>3. Crie conteúdo com IA</span>
          </div>
        </div>
        <div class="flex flex-col gap-8 items-center justify-center mt-0 p-4">
          <h2 class="text-[1.5em] font-bold">
            O que você pode fazer com nossa plataforma?
          </h2>
          <p>Escrever posts com inteligência artificial</p>
          <p>Buscar referências em fontes que você cadastra</p>
          <p>Publicar posts em vários sites WordPress ao mesmo tempo</p>
        </div>
      </div>
      <div
        class="min-h-[50vh] lg:min-h-screen w-full lg:w-1/2 flex flex-col items-center justify-center"
      >
        <div class="flex flex-col text-center">
          <h2 class="hidden text-[2em] font-bold lg:block mb-2">
            Login / Cadastro
          </h2>
          <div class="lg:hidden mb-8">
            <logo-component class="scale-[200%] mb-8"></logo-component>
            <h1 class="text-[1em] font-normal">
              Crie e gerencie posts em sites WordPress
            </h1>
          </div>
          <div class="m-4 flex items-center justify-center">
            <div
              id="g_id_onload"
              data-client_id="336651661679-cus5lqpirff0qffjvp5os9pqnsj0l8d6.apps.googleusercontent.com"
              data-auto_prompt="true"
            ></div>
            <div
              ref="button"
              class="g_id_signin"
              data-type="standard"
              data-size="large"
              data-theme="outline"
              data-text="continue_with"
              data-shape="rectangular"
              data-logo_alignment="left"
              data-callback="login"
            ></div>
          </div>
          <div class="mt-8 flex justify-center gap-4 mb-8 lg:mb-0">
            <router-link to="/politicas-de-privacidade"
              >Políticas de Privacidade</router-link
            ><spa>|</spa>
            <router-link to="/termos-de-uso">Termos de Uso</router-link>
          </div>
        </div>
      </div>
    </div>
    <div class="w-full bg-white p-8">
      <div class="max-w-7xl mx-auto">
        <h2
          data-placeholder="Texto ou prompt, exemplo: Gere um artigo com SEO otimizado sobre Como escrever um post sobre IA"
        >
          <br data-cke-filler="true" />
        </h2>
        <h1>IA xPress: Ferramenta de Criação de Textos por IA</h1>
        <p><br data-cke-filler="true" /></p>
        <h3><strong>Inovação e Eficiência</strong></h3>
        <p><br data-cke-filler="true" /></p>
        <p>
          Na era digital, a <strong>produção de conteúdo</strong> se tornou
          essencial para empresas que desejam conquistar visibilidade online.
          Uma nova
          <a
            target="_blank"
            rel="noopener noreferrer"
            href="https://example.com/ferramenta-de-texto-ia"
            >ferramenta revolucionária</a
          >
          utiliza<strong> Inteligência Artificial (IA)</strong> para criar
          textos de forma rápida e eficiente. Com isso, se destaca como uma
          solução poderosa para quem busca otimizar a produção de conteúdo.
        </p>
        <p><br data-cke-filler="true" /></p>
        <h3><strong>Criação Automática e Postagem Simultânea</strong></h3>
        <p><br data-cke-filler="true" /></p>
        <p>
          Essa <strong>ferramenta de IA</strong> não apenas gera textos de
          qualidade, mas também é capaz de
          <strong>postar simultaneamente</strong> em múltiplos sites
          <strong>WordPress</strong>. Portanto, marcas que gerenciam diversos
          blogs podem se beneficiar enormemente dessa funcionalidade. Além
          disso, a automação do processo reduz significativamente o tempo e o
          esforço necessário para manter vários sites atualizados.
        </p>
        <p><br data-cke-filler="true" /></p>
        <h3><strong>Vantagens e Funcionalidades</strong></h3>
        <p><br data-cke-filler="true" /></p>
        <p>
          Entre as principais vantagens da ferramenta está a capacidade de
          personalizar o conteúdo de acordo com o público-alvo. A inteligência
          artificial analisa dados e ajusta a linguagem e o tom dos textos,
          garantindo que cada publicação ressoe com os leitores de forma eficaz.
        </p><br/>
        <p>
          Além disso, a ferramenta oferece insights valiosos sobre
          <strong>SEO</strong>, aprimorando a visibilidade dos textos nos
          mecanismos de busca. Isso é crucial para aumentar o tráfego orgânico
          e, consequentemente, o sucesso das estratégias de marketing digital.
        </p>
        <p><br data-cke-filler="true" /></p>
        <h3><strong>Integração e Facilidade de Uso</strong></h3>
        <p><br data-cke-filler="true" /></p>
        <p>
          Por ser integrada com o <strong>WordPress</strong>, a ferramenta é
          fácil de usar, mesmo para aqueles que não possuem conhecimento técnico
          avançado. A interface intuitiva permite que usuários criem, editem e
          publiquem conteúdo sem complicações.
        </p>
        <p>
          Com essas características, a adoção dessa solução se torna uma escolha
          estratégica para empresas que desejam se destacar no mercado digital e
          maximizar seu alcance.
        </p>
      </div>
    </div>
  </div>
</template>

<script>
import LogoComponent from "../components/LogoComponent.vue";
import ProfileComponent from "../components/ProfileComponent.vue";
export default {
  components: { LogoComponent, ProfileComponent },
  name: "HomeView",
  computed: {
    me() {
      return this.$store.state.me;
    },
  },
  mounted() {
    if (localStorage.user) {
      this.$router.push({ name: "start" });
    } else {
      google.accounts.id.initialize({
        client_id:
          "336651661679-cus5lqpirff0qffjvp5os9pqnsj0l8d6.apps.googleusercontent.com",
        callback: this.login,
      });
      google.accounts.id.renderButton(
        this.$refs.button,
        { theme: "outline", size: "large" } // customization attributes
      );
      google.accounts.id.prompt(); // also display the One Tap dialog
    }
  },
  methods: {
    login(token) {
      localStorage.user = JSON.stringify(token);
      this.$axios.post("/auth").then(() => {
        this.$axios.get("/sites").then((res) => {
          if (res.data.length === 0) {
            this.$router.push({ name: "add-site" });
          } else {
            this.$router.push({ name: "start" });
          }
        });
      });
    },
  },
};
</script>

<style></style>
