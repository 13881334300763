<template>
  <div class="text-center gap-4 flex flex-col md:flex-row justify-start items-start">
    <div class="text-center gap-4 flex justify-between w-auto items-center">
      <div class="w-[120px]">
        <img
        :src="me.picture"
        referrerpolicy="no-referrer" 
        @click="$router.push('/dashboard')"
        class="rounded-[80px] border-4 border-white shadow-sm w-[80px] cursor-pointer"
        />
      </div>
      <div class="text-center gap-2 flex flex-col justify-between w-full items-center">
        <span class="block text-left w-full">{{ me.email }}</span>
        <div class="text-center gap-4 flex justify-start w-full items-center">
          <router-link :to="{name: 'start'}" class="btn"
            >Dashboard</router-link
          >
          <div class="flex gap-4 items-center">
            <button @click="logout" class="btn w-full danger">Sair <fa icon="fa-triangle-exclamation" v-if="syncing" fade /></button>
          </div>
        </div>
      </div>
    </div>
    <div class="grid grid-cols-2 md:grid-cols-7 w-full gap-4 items-start">
      <button :class="{active: $route.name == 'search'}" @click="$router.push({name: 'search'})" id="show_search" class="flex flex-col py-4 px-8 bg-slate-300 rounded-md items-center gap-2">
        <fa icon="fa-search" class="text-[24px]" /> <span>Pesquisar</span>
      </button>
      <button :class="{active: $route.name == 'write'}" @click="$router.push({name: 'write'})" id="show_create" class="flex flex-col py-4 px-8 bg-slate-300 rounded-md items-center gap-2">
        <fa icon="fa-edit" class="text-[24px]" /> <span>Criar</span>
      </button>
      <button :class="{active: $route.name == 'schedule'}" @click="$router.push({name: 'schedule'})" id="show_schedule" class="flex flex-col py-4 px-8 bg-slate-300 rounded-md items-center gap-2">
        <fa icon="fa-calendar-days" class="text-[24px]" /> <span>Agendar</span>
      </button>
      <button :class="{active: $route.name == 'history'}" @click="$router.push({name: 'history'})" id="history" class="flex flex-col py-4 px-8 bg-slate-300 rounded-md items-center gap-2">
        <fa icon="fa-clock-rotate-left" class="text-[24px]" /> <span>Histórico</span>
      </button>
      <button :class="{active: $route.name == 'sites'}" @click="$router.push({name: 'sites'})" id="sites" class="flex flex-col py-4 px-8 bg-slate-300 rounded-md items-center gap-2">
        <fa icon="fa-globe" class="text-[24px]" /> <span>Sites</span>
      </button>
      <button :class="{active: $route.name == 'postar'}" @click="$router.push({name: 'postar'})" id="postar" class="flex flex-col py-4 px-8 bg-slate-300 rounded-md items-center gap-2">
        <fa icon="fa-list-check" class="text-[24px]" /> <span>Fila</span>
      </button>
      <a href="https://wordpress.org/plugins/iaxpress/" target="_blank" id="download" class="flex flex-col py-4 px-8 bg-slate-300 rounded-md items-center gap-2">
        <fa icon="fa-download" class="text-[24px]" /> <span>Download</span>
      </a>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      syncing: false,
      loggingout: false,
    };
  },
  computed: {
    me() {
      return this.$store.state.me;
    },
  },
  methods: {
    sync() {
      if (this.syncing) return;
      this.syncing = true;
      return this.$axios
        .post("/sync", this.$store.state.configs)
        .then(() => (this.syncing = false))
        .catch(() => (this.syncing = false));
    },
    logout() {
      if (localStorage.user) {
        this.loggingout = true
        this.sync().then(() => {
          this.$axios.post("/session").then(() => {
            delete localStorage.user;
            this.loggingout = false
            location.reload();
          });
        });
      }
    },
  },
};
</script>

<style scoped lang="scss">
.search svg{
  height:16px;
  fill:#fff;
  display: block;
  margin:auto;
}
button {
  &.active {
    @apply bg-green-500 text-white;
  }
}
</style>
